















import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import AttachmentViewerDetails from '@/components/attachment-viewer-details.vue';
import SalesForceInfosModel from '@/models/b2sp/sales-force-infos.model';
import AttachmentModel from '@/models/crm/attachment.model';
import { B2SPCustomFieldTypeEnum } from '@/enums/b2sp/custom-field-type.enum';
import { AttachmentTypeEnum } from '@/enums/crm/attachment-type.enum';
import { IKeyValue } from '@/interfaces/key-value.interface';

@Component({
  components: {
    AttachmentViewerDetails,
  },
})
export default class B2SPSalesForceInformation extends Vue {
  @Prop({ required: true })
  salesForceInfo!: SalesForceInfosModel;

  get noAttachmentInfo(): string {
    return `${this.$t('crm.view.salesForceAdditionalInfo.noAttachment')}`.toString();
  }

  get isTextArea(): boolean {
    return this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.TextArea;
  }

  get isIntegrationList(): boolean {
    return this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.List
    || this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.IntegrationList
    || this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.SQL;
  }

  get isAttachment(): boolean {
    return this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.Attachment;
  }

  get isDate(): boolean {
    return this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.Date;
  }

  get isLogical(): boolean {
    return this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.Logical;
  }

  get useTextField(): boolean {
    return this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.Text
    || this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.Number
    || this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.Date
    || this.salesForceInfo.tipo === B2SPCustomFieldTypeEnum.Logical
    || this.isIntegrationList;
  }

  get attachment(): AttachmentModel {
    const res = new AttachmentModel();
    res.id = this.salesForceInfo.id;
    res.clientId = this.salesForceInfo.uuid;
    res.type = AttachmentTypeEnum.Other;
    res.path = this.salesForceInfo.valor;
    res.src = this.salesForceInfo.valor;
    return res;
  }

  get valuesList(): IKeyValue[] {
    const res: IKeyValue[] = [];
    for (let i = 0; i < this.salesForceInfo.valoresListaCodigo.length; i += 1) {
      if (this.salesForceInfo.valoresListaCodigo[i] != null && this.salesForceInfo.valoresListaDescricao[i] !== null) {
        res.push({ key: this.salesForceInfo.valoresListaCodigo[i],
          value: this.salesForceInfo.valoresListaDescricao[i] });
      }
    }
    return res;
  }

  get valueFromList(): string {
    const index = this.valuesList.findIndex((item) => item.key === this.salesForceInfo.valor);
    if (index !== -1) {
      return this.valuesList[index].value;
    }
    return '';
  }

  get stringValue(): string {
    if (this.isIntegrationList && this.salesForceInfo.valor) return this.valueFromList;
    if (this.isLogical) return this.salesForceInfo.valor === 'true' ? 'Sim' : 'Não';
    if (this.isDate) return dayjs(this.salesForceInfo.valor).format('DD/MM/YYYY HH:mm:ss');
    if (this.salesForceInfo.valor) return this.salesForceInfo.valor;
    return '';
  }
}
