



































import { Vue, Component, Prop } from 'vue-property-decorator';
import CrmAttachmentsViewerDetailsDialog from '@/components/crm/attachments-viewer-details-dialog.vue';
import { IKeyValue } from '@/interfaces/key-value.interface';
import AttachmentModel from '@/models/crm/attachment.model';

@Component({
  components: {
    CrmAttachmentsViewerDetailsDialog,
  },
})
export default class AttachmentViewerDetails extends Vue {
  @Prop({ required: true })
  attachment!: AttachmentModel;

  @Prop({ required: true })
  attachments!: AttachmentModel[];

  showDetails = false;

  fileTypeIcons = {
    video: 'mdi-video',
    audio: 'mdi-headphones',
    pdf: 'mdi-adobe-acrobat',
    unknown: 'mdi-file',
  };

  get fileTypes(): IKeyValue {
    const mappedTypes: IKeyValue = {};

    this.attachments.forEach((file) => {
      const filename = file.name || file.path;

      const regexp = new RegExp(/\.[0-9a-z]{1,5}$/i);
      const regexpMatch = filename.match(regexp) || [];

      const typeMatrix = {
        '.jpg': 'image',
        '.jpeg': 'image',
        '.gif': 'image',
        '.png': 'image',
        '.mp4': 'video',
        '.pdf': 'pdf',
        '.mp3': 'audio',
      };

      mappedTypes[`${file.type}_${file.id}`] = typeMatrix[regexpMatch[0]] || 'unknown';
    });

    return mappedTypes;
  }

  onShowAttachment(): void {
    this.showDetails = true;
  }
}
